<template>
	<div class="main">
		<div class="from">
			<div class="team">
				<img src="../assets/other/team.png" alt="">
			</div>
			<div class="title2" style="text-align: center;">
				<h3>想独立成行？想拥有更灵活的行程？</h3>
				<p>赶快来提交需求，为您策划专属活动</p>
			</div>
			<div class="formmsg">
				<table>
					<tr>
						<td>出发地</td>
						<td><input type="text" placeholder="请输入出发地" v-model="form.origin" class="input"></td>
						<td>目的地</td>
						<td><input type="text" placeholder="请输入目的地"  v-model="form.destination" class="input"></td>
					</tr>
					<tr>
						<td>预计出游日期</td>
						<td>
							<el-date-picker
							v-model="form.travel_date"
							type="date"
							placeholder="选择日期"
							format="yyyy 年 MM 月 dd 日"
      						value-format="yyyy-MM-dd">
							</el-date-picker>
						</td>
						<td>游玩天数</td>
						<td><input type="text" placeholder="请输入游玩天数"  v-model="form.days" class="input"></td>
					</tr>
					<tr style="height:80px">
						<td>出游人数</td>
						<td>
							<span class="a1">成人</span>
							<el-input-number size="mini" :min="0"  v-model="form.adults_number"></el-input-number>
							
						</td>
						<td class="">儿童 </td>
						<td  class="a2">
							<el-input-number size="mini" :min="0"  v-model="form.children_number"></el-input-number>
							<p style="font-size:12px;">* 12周岁(不含)以内</p>
						</td>
						
					</tr>
					<tr>
						<td>联系人</td>
						<td><input type="text" placeholder="请输入联系人" v-model="form.contact" class="input"></td>
						<td>手机号码</td>
						<td><input type="text" placeholder="请输入手机号码" :maxlength="11" v-model="form.phone" class="input"></td>
					</tr>
					<tr>
						<td>微信号</td>
						<td><input type="text" placeholder="请输入微信号" v-model="form.wechat_number" class="input"></td>
						
					</tr>
					<tr>
						<td></td>
						<td>
							<el-checkbox v-model="iswechat" @change="changes">同手机号</el-checkbox>
						</td>
						
					</tr>
				</table>
				<div class="but" @click="add">提交需求</div>
			</div>
		</div>
		<div class="case">
			<h2>经典案例</h2>
			<div class="caselist">

				<div class="caseitem" v-for="(item,i) in caselist" :key="i" @click="toinfo(item.id)">
					<div class="img">
						<img :src="item.goods_pic" alt="">
					</div>
					<div class="text">
						<p>{{item.goods_name}}</p>
					</div>
				</div>
				
			</div>
			<div class="page">
				<!-- <img src="../assets/other/pleft.png" alt="">
				<p>1/1</p>
				<img src="../assets/other/pright.png" alt=""> -->
			</div>
		</div>
		
		<div class="case">
			<h2>合作品牌</h2>
			<div class="caselist">

				<div class="caseitem" v-for="(item,i) in brandlist" :key="i" >
					<div class="img">
						<img :src="item.pic" alt="">
					</div>
					<!-- <div class="text">
						<p>{{item.name}}</p>
					</div> -->
				</div>
				
			</div>
			<div class="page">
				<!-- <img src="../assets/other/pleft.png" alt="">
				<p>1/1</p>
				<img src="../assets/other/pright.png" alt=""> -->
			</div>
		</div>
		<div style="height: 20px;"></div>
	
	
	   <el-dialog :visible.sync="dia">
			<div class="osn">
				<p><img src="../assets/signup/pass.png" alt=""></p>
				<p class="p1">您的需求我们已经收到</p>
				<p class="p2">需求单号：{{order_sn}}</p>
				<p class="p3">
					我们将于一个工作日内联系您 
				</p>
				<p class="p3" style="margin-bottom: 30px;">
					电话号码以<span>021-****</span>开头，请您注意接听哦~
				</p>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {goodsCaseListAPI,teamAddAPI,brandListAPI} from "@/api/team"
export default {

  data () {
    return {
	  dia:false,
      caselist:[],
	  brandlist:[],
	  form:{
		  origin:"",
		  destination:"",
		  travel_date:"",
		  days:"",
		  adults_number:1,
		  children_number:0,
		  contact:"",
		  phone:"",
		  wechat_number:""
	  },
	  iswechat:false,
	  order_sn:"000"
    }
  },
  created () {
   this.getcaselist()
   this.getbrand()
  },
  methods: {
	  toinfo(id){
		  this.$router.push({ path: '/caseInfo',query:{id} })
	  },
	  getcaselist(){
		  goodsCaseListAPI().then(res=>{
			  this.caselist=res.cateList
		  })
	  },
	  getbrand(){
		  brandListAPI().then(res=>{
			  this.brandlist=res.cateList
		  })
	  },
	  add(){
		 if (!localStorage.getItem('token')) {
			  this.$message.info("请先登录！")
			  return
		 }
		  teamAddAPI(this.form).then(res=>{
			  this.order_sn=res.order_sn
			  this.dia=true
		  })
	  },
	  changes(e){
		  if(e){
			  this.form.wechat_number=this.form.phone
		  }
		  console.log(e)
	  }
  }
}
</script>

<style lang="scss" >

.el-dialog__body {
	padding: 10px 20px;
}
.osn{
	text-align: center;
	
	.p1{
		font-weight: 500;
		color: #333333;
		font-size: 20px;
		line-height: 35px;
	}
	.p2{
		font-size: 14px;
		color: #666;
		margin-bottom: 10px;
		line-height: 30px;
	}
	.p3{
		font-size: 14px;
		color: #666;		
	}
	span{
		color: #FF7C00;
	}
}

.from{
	width:1200px;
	// height: 500px;
	margin: 0px auto;

	background-color: #fff;
	border-radius: 10px;
	padding-bottom: 10px;
	.team{
		width: 83px;
		margin:0px auto;
		img{
			width: 80px;
			margin-top: 30px;
		}
	}
	.title2{
		text-align: center;
		margin: 10px auto;
		h3{
			font-size: 18px;
			font-weight: 500;

			color: #333333;
			line-height: 35px;
			letter-spacing: 1px;
		}
		p{
			font-size: 14px;
			color: #666;
			line-height: 25px;
		}

	}
	.formmsg{
		width:630px;

		margin: 0px auto;
		// background-color: red;
		tr{
			height:45px;
			td{
				text-align: right;
				min-width:100px;
				font-weight: 400;

				color: #333333;
				line-height: 22px;
				.input{
					margin-left:20px;
					height:35px;
					width:190px ;
					background: #f8f8f8;
					border-radius: 4px;
					text-indent: 1em;
					border: 1px solid #DCDFE6;
				}
				.el-date-editor.el-input, .el-date-editor.el-input__inner {
					width: 190px;
				}
				.a1{
					margin-right: 25px;
					margin-left: 5px;
				}
				
			}
			.a3{
				text-align: left;
				margin-left: 10px;
				span{
					font-size: 12px;
					color: #666;
				}
			}
		}
		.a2{
			position: relative;
		}
		.a2 p{
			position: absolute;
			top:55px;
			right: -0px;
		}
		.but{
			width: 240px;
			height: 45px;
			margin: 30px auto;
			background: #ff7c00;
			border-radius: 8px;
			font-size: 16px;
			font-weight: 500;
			text-align: center;
			color: #ffffff;
			line-height: 45px;

		}
	}
}

.case{
	width:1200px;

	margin: 20px auto;
	background-color: #fff;
	border-radius: 10px;
	padding: 10px 0;
	h2{
		font-size: 24px;
		font-family: PingFangSC, PingFangSC-Medium;
		font-weight: 500;
		text-align: center;
		color: #333333;
		line-height: 43px;
	}
	.caselist{
		display: flex;
		flex-wrap: wrap;

		.caseitem{
			margin:10px;
			background-color: #f5f5f5;
			border-radius: 5px;
			// height: 254px;
			width: 380px;
			box-sizing: border-box;
			.img{
				width: 380px;
				height: 190px;
				overflow: hidden;

				img{
					width: 100%;
					min-height: 190px;
					border-radius: 5px 5px 0 0;
				}
			}
			.text{
				padding: 5px;
				p{
					font-size: 16px;
					font-weight: 500;
					text-align: left;
					color: #333333;
					line-height: 26px;
				}
			}
		}
	}
	.page{
		width: 120px;
		// background-color: red;
		// margin-top: 15px;
		display: flex;
		height: 25px;
		margin: 15px auto 5px;
		p{

			margin: 0 8px;
			font-size: 14px;
			text-align: center;
			color: #333333;
			line-height: 25px;
		}
	}

}

.brand{
	width:1200px;
	margin: 20px auto;
	background-color: #fff;
	border-radius: 10px;
	padding: 10px 0;
	// height: 600px;
	h2{
		font-size: 24px;
		font-family: PingFangSC, PingFangSC-Medium;
		font-weight: 500;
		text-align: center;
		color: #333333;
		line-height: 43px;
	}
	img{
		width: 1200px;
	}
}

</style>
