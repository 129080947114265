import myRequest from '../http/index.js'
// 经典案例
export function goodsCaseListAPI(params){
    return myRequest({
        url:"index.php/index/article/goodsCaseList",         
        params,
        method:"get"
      })
}
// 经典案例详情
export function caseInfoAPI(params){
  return myRequest({
      url:"index.php/index/article/goodsCaseFind",         
      params,
      method:"get"
    })
}
// 接口：活动集锦
export function teamAddAPI(params){
  return myRequest({
      url:"index.php/index/Team/TeamAdd",         
      params,
      method:"post"
    })
}
// 合作品牌
export function brandListAPI(params){
  return myRequest({
      url:"index.php/index/article/brandList",         
      params,
      method:"get"
    })
}